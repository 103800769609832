export function getBearerTokenOpts(auth0Token: string) {
  return {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };
}

export async function fetchWithAuth0Token(
  url: string,
  auth0Token: string,
  opts: RequestInit = {},
) {
  return await fetch(
    url,
    Object.assign({}, opts, getBearerTokenOpts(auth0Token)),
  );
}

export function checkPQAuth0UserRole(user: any) {
  if (user) {
    const roles = user['https://safeguard-pay.com/roles'];
    console.log(roles);
    if (roles.includes('invoice_quality__user')) {
      console.log('VALID ROLE');
    } else {
      window.location.replace(
        'https://www.app.safeguardglobal.com/?error=No%20Access%20to%20Invoice%20Quality',
      );
    }
  } else {
    window.location.replace(
      'https://www.app.safeguardglobal.com/?error=No%20Access%20to%20Invoice%20Quality',
    );
  }
  return true;
}
