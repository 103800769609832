export const ENV = `${process.env.REACT_APP_ENVIRONMENT_NAME}`;
export const AUTH0_CLIENT_ID = `${process.env.REACT_APP_AUTH0_PQ_CLIENT_ID}`;
export const AUTH0_DOMAIN = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
export const AUTH0_AUDIENCE = 'https://api.pq.data.safeguard-pay.com';

// console.log('Domain & Client_Id:', AUTH0_DOMAIN, AUTH0_CLIENT_ID);

export const qualityS3KeyFolder = 'services/invoice-quality/resources';
export const consolidatorS3KeyFolder =
  'services/payroll-consolidator/resources';
